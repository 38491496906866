<template>
    <div class="active modal-register">
        <div class="container-modal form-modal">
            <div class="container-holder">
                <div class="header-modal">
                    <div class="icon"><icon-lock-bold /></div>
                    <div class="title" v-html="$t('reset_password.title')"></div>
				    <div class="description">{{ $t('reset_password.description') }}</div>
                </div>

                <div class="form-section-register">
                    <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error }">
                        <div class="input-box">
                            <div class="icon-right-new"><icon-email /></div>
                            <input type="email" :placeholder="$t('general.email-placeholder')" class="input-text" v-model="email">
                        </div>
                    </div>

                    <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.password.$error, complete: password != '' && !$v.password.$error}">
                        <div class="input-box">
                            <div class="icon-right-new"><icon-password /></div>
                            <input type="password" :placeholder="$t('login.password-placeholder')" class="input-text" v-model="password">
                        </div>
                        <div class="error-msg" v-if="!$v.password.minLength">{{ $t('validator.password_min_length') }}</div>
                        <div class="error-msg" v-else-if="!$v.confirm_password.sameAsPassword">{{ $t('validator.same_password') }}</div>
                    </div>

                    <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.confirm_password.$error, complete: confirm_password == password && !$v.confirm_password.$error}">
                        <div class="input-box">
                            <div class="icon-right-new"><icon-password /></div>
                            <input type="password" :placeholder="$t('reset_password.password-confirm-placeholder')" class="input-text" v-model="confirm_password">
                        </div>
                    </div>
                </div>

                <div class="submit-footer">
                    <div v-if="error_msg" class="error-msg" >{{ error_msg }}</div>
                    <div class="submit-form">
                        <button id="buttonSubmit" class="button-submit" @click="resetPassword">
                            <div class="loader-spin" v-if="loadingSubmit"></div>
                            <div class="text" v-else>{{$t('reset_password.button-action')}}</div>
                        </button>
                    </div>

                    <!-- <div class="bottom-links">
                        <router-link :to="{path: '/price'}" class="go-register">{{ $t('login.no-account') }}</router-link>
                        <router-link :to="{path: '/password/forgot' }" class="forgot-password">{{ $t('login.forgot-password') }}</router-link>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconLockBold from '../Icons/LockBold'
import IconLock from '../Icons/Lock'
import IconPassword from '../Icons/Password'
import IconEmail from '../Icons/Email'

import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
	data(){
		return {
            email: "",
            password: "",
            confirm_password: "",
            loadingSubmit: false,
            error_msg: ''
		}
    },
    components: {
        IconLockBold,
        IconLock,
        IconPassword,
        IconEmail,
    },
    validations: {
        email: {required},
		password: {required,minLength: minLength(6)},
		confirm_password: {required, sameAsPassword: sameAs('password')}
	},
	methods: {
        resetPassword() {
            var buttonName = 'buttonSubmit'
            var btnSubmit = document.getElementById(buttonName);

            btnSubmit.disabled = true;
            this.loadingSubmit = true;

            this.$v.$touch()

            if(!this.$v.$invalid){
                axios.post("/auth/reset/password", {
                    token: this.$route.params.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.confirm_password
                })
                .then(result => {
                    this.$router.push({ name: 'password-reset-success' });
                }).catch(error => {
                    if(error.response) {
                        this.error_msg = error.response.data.message;
					}
                    btnSubmit.disabled = false;
                    this.loadingSubmit = false;
                });
            }else{
                btnSubmit.disabled = false;
                this.loadingSubmit = false;
            }
        },
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>

<style lang="scss">
    .forgot-modal{
        .content-modal{
            .action-form{
                margin-top: 0;
            }
        }
    }
</style>